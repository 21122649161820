<!-- @Author: Yu_Bo -->
<template>
  <div class='digital_twin'>
    <div class="digital_main">
      <div class="d_f mb30">
        <div class="title_text">
          <span>*</span> 模特名称
        </div>
        <div class="input">
          <el-input v-model="name" placeholder="请输入模特名称"></el-input>
        </div>
      </div>
      <div class="d_f">
        <upload-video @successVideo="successVideo"></upload-video>
        <div class="d_f">
          <div class="title_text">
            示例视频
          </div>
          <div class="sample_video">
            <video controls="controls" class="video_cover" style=""
              poster="http://yc-digital.oss-cn-shenzhen.aliyuncs.com/image/20230619/ynxXUSqVEwes-Uqlwd.png"
              src="https://yc-digital.oss-cn-shenzhen.aliyuncs.com/video/20230612/iMAXYPfTGLwv-hJCdv.mp4"
              controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>
          </div>
        </div>
      </div>
      <div class="tips_main">
        <div class="tips_main_l">
          <div class="tips_main_tit">
            <img src="@/assets/images/workben/warn.png" alt="">
            <span>上传提示</span>
          </div>
          <div class="li" v-for="item,index in warnList" :key="index">{{ item }}</div>
        </div>
        <div class="tips_main_r">
          <div class="tips_main_tit">
            <img src="@/assets/images/workben/err.png" alt="">
            <span>不佳示范</span>
          </div>
          <div class="ul">
            <div class="li" v-for="item,index in errList" :key="index">
              <img :src="item.img" alt="">
              <p>{{item.text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="digital_main mt20">
      <div class="d_f">
        <upload-grant @successGrant="successGrant"></upload-grant>
        <div class="d_f">
          <div class="title_text">
            示例视频
          </div>
          <div class="sample_video">
            <video controls="controls" class="video_cover" style=""
              poster="http://yc-digital.oss-cn-shenzhen.aliyuncs.com/image/20230619/ynxXUSqVEwes-Uqlwd.png"
              src="https://yc-digital.oss-cn-shenzhen.aliyuncs.com/video/20230612/iMAXYPfTGLwv-hJCdv.mp4"
              controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>
          </div>
        </div>
      </div>
      <div class="tips_main" style="height: 141px;">
        <div class="tips_main_l">
          <div class="tips_main_tit">
            <img src="@/assets/images/workben/warn.png" alt="">
            <span>上传提示</span>
          </div>
          <div class="li">1. 请您上传一段不少于30秒的手持证件视频来确认您的身份；</div>
          <div class="li">2. 确保您定制模特为审核视频本人，且对后续制作内容负责，不会创作违法内容；</div>
        </div>
        <div class="tips_main_r">
          <div class="tips_main_tit">
            <img src="@/assets/images/workben/warning.png" alt="">
            <span>注意事项</span>
          </div>
          <div class="ul">
            <div class="li" v-for="item,index in tipsList" :key="index">
              <img style="height: 38px;" :src="item.img" alt="">
              <p>{{item.text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="digital_bottom mt20">
      <div class="digital_bottom_l">
        模型有效期：<span>{{video_date || 0}}</span>天
      </div>
      <div class="digital_bottom_r">
        <div class="c3">是否扣减次数</div>
        <el-select v-model="type" placeholder="请选择">
          <el-option v-for="item in options" :key="item.label+''" :label="item.value" :value="item.label+''">
          </el-option>
        </el-select>
        <div class="c6" v-if="type=='2'">模特定制剩余：{{major_cust}}次</div>
        <div class="c6" v-else>总计：{{video_price}}钻<img src="@/assets/images/person/accountManage/zuan.png" alt=""></div>
        <el-button size="small" type="primary" @click="confirm">结 算</el-button>
      </div>
    </div>
    <!-- 提示弹框 -->
    <point-out ref='dialogTip' type='account' @close='deductNumber' dialogTitle="提示" dialogWidth='380px'
      :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">您确定要扣减给"{{name}}"一次训练次数吗？</div>
      </div>
    </point-out>
    <!-- 支付 -->
    <pay-diamond ref='dialogdiamond' @renovate='renovateBtn' @paySuccess='paySuccessBtn'></pay-diamond>
  </div>
</template>

<script>
  import PayDiamond from '@/components/pay_page/pay_diamond.vue' //支付
  import UploadVideo from '../components/upload_video/index.vue' //上传训练视频
  import UploadGrant from '../components/upload_grant/index.vue' //上传授权视频或证书
  import PointOut from '@/components/point_out/point_out.vue' //提示弹框
  export default {
    components: {
      PayDiamond,
      UploadVideo,
      UploadGrant,
      PointOut
    },
    data() {
      return {
        warnList: [
          '1. 上传本地视频，人工审核精选过滤后为您训练输出更精美的数字人形象；',
          '2. 训练视频仅支持时长大于1min，小于5min，尺寸为9:16/16:9，分辨率大于720p小于4k的MP4/MOV格式视频，2k以上会自动压缩为1080p；',
          '3. 视频要求为正面、五官清晰无遮挡，不要歪头、侧头；',
          '4. 请保持视频中人脸比例适中，涵盖肩膀区域，头部比例不要过大或者过小；',
          '5. 上传视频将用于数字人定制，请慎重选择视频上传；',
        ],
        errList: [{
            img: require('@/assets/images/workben/z1.png'),
            text: '非人物照',
          },
          {
            img: require('@/assets/images/workben/z2.png'),
            text: '五官遮挡',
          },
          {
            img: require('@/assets/images/workben/z3.png'),
            text: '模糊不清',
          },
          {
            img: require('@/assets/images/workben/z4.png'),
            text: '衣着不当',
          },
          {
            img: require('@/assets/images/workben/z5.png'),
            text: '面部过大',
          },
          {
            img: require('@/assets/images/workben/z6.png'),
            text: '多张人脸',
          },
        ],
        tipsList: [{
            img: require('@/assets/images/workben/z7.png'),
            text: '光线充足',
          },
          {
            img: require('@/assets/images/workben/z8.png'),
            text: '周围安静',
          },
          {
            img: require('@/assets/images/workben/z9.png'),
            text: '面部无遮挡',
          },
          {
            img: require('@/assets/images/workben/z10.png'),
            text: '声音清晰',
          },
        ],
        options: [],
        video_price: '', //训练价格
        video_date: '', //训练有效期
        diamond: '', //剩余钻石
        major_cust: '', //剩余模特定制次数

        name: '',
        training_video: '',
        grant_file: '',
        type: '1',
      }
    },
    created() {
      this.getParams()
    },
    methods: {
      // 获取配置
      getParams() {
        this.$workbenApi.getModelParams().then(res => {
          if (res.code == 1000) {
            this.video_price = res.result.video_price;
            this.video_date = res.result.video_date;
            this.diamond = res.result.diamond;
            this.major_cust = res.result.major_cust;
            this.options = res.result.is_mo;
          }
        })
      },
      // 训练视频上传
      successVideo(val) {
        this.training_video = val
      },
      // 授权上传
      successGrant(val) {
        this.grant_file = val
      },
      confirm() {
        if (this.name == '') {
          this.$errMsg('请输入模特名称')
          return
        } else if (this.training_video == '') {
          this.$errMsg('请上传模特视频')
          return
        } else if (this.grant_file == '') {
          this.$errMsg('请上传形象授权')
          return
        }
        if (this.type == '2') {
          this.prompt()
        } else {
          this.unDeductNumber()
        }
      },
      // 提示
      prompt() {
        this.$refs.dialogTip.openDialogBtn()
      },
      // 扣减次数下单
      deductNumber() {
        let obj = {
          name: this.name,
          training_video: this.training_video,
          grant_file: this.grant_file,
        }
        this.$workbenApi.directModel(obj).then(res => {
          if (res.code == 1000) {
            this.$succMsg(res.message)
          } else {
            this.$errMsg(res.message)
          }
        })
      },
      // 不扣减次数下单
      unDeductNumber() {
        let obj = {
          name: this.name,
          training_video: this.training_video,
          grant_file: this.grant_file,
        }
        this.$workbenApi.unDirectModel(obj).then(res => {
          if (res.code == 1000) {
            let parmas = {
              diamond: this.diamond,
              video_price: this.video_price,
              order_num: res.result.order_num,
              order_id: res.result.order_id,
              name: this.name,
              training_video: this.training_video,
              grant_file: this.grant_file,
            }
            this.$refs.dialogdiamond.openDialogBtn(parmas, 3)
          } else {
            this.$errMsg(res.message)
          }
        })


      },
      // 支付关闭
      renovateBtn() {
        this.getParams()
      },
      // 支付成功
      paySuccessBtn() {
        var that = this
        setTimeout(function() {
          that.$store.dispatch('tabsArr/delTabList')
        }, 2000)
      },
    },
  }
</script>

<style lang='scss' scoped>
  .digital_twin {
    .digital_main {
      width: 100%;
      background: #ffffff;
      border-radius: 10px;
      padding: 30px 20px;

      .title_text {
        width: 78px;
        font-size: 14px;
        text-align: right;
        color: #333333;
        margin-right: 10px;
        line-height: 40px;

        span {
          color: #e00c25;
        }
      }

      .input {
        width: 300px;
      }

      ::v-deep .el-upload {
        width: 100%;
        height: 100%;
      }

      .upload_video {
        width: 708px;
        height: 410px;
        background: #f5f7fa;
        border-radius: 4px;
        border: 1px dashed #dddfe6;
        margin-right: 40px;
        cursor: pointer;
        position: relative;

        .upload_img {
          width: 24px;
          height: 24px;
          margin: 170px auto 0;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .upload_text {
          font-size: 14px;
          color: #2e4bf2;
          line-height: 20px;
          text-align: center;
          padding-top: 6px;
        }
      }

      .sample_video {
        width: 708px;
        height: 410px;
        background: #1a1a1a;
        border-radius: 4px;
      }

      .video_cover {
        width: 708px;
        height: 410px;
        border-radius: 4px;
        -o-object-fit: contain;
        object-fit: contain;
        background: #1a1a1a;
      }

      .tips_main {
        width: 1544px;
        height: 227px;
        background: #f5f7fa;
        border-radius: 4px;
        padding: 22px 24px;
        display: flex;
        margin-left: 88px;
        margin-top: 30px;

        .tips_main_tit {
          display: flex;
          align-items: center;

          i {
            color: #fc9393;
            font-size: 18px;
          }

          img {
            width: 16px;
            height: 16px;
          }

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            margin-left: 6px;
          }
        }

        .tips_main_l {
          width: 810px;

          .li {
            width: 456px;
            font-size: 12px;
            color: #9097a5;
            line-height: 20px;
            padding-top: 10px;
          }
        }

        .tips_main_r {
          .ul {
            display: flex;
            align-items: center;
            margin-top: 16px;

            .li {
              margin-right: 30px;

              img {
                width: 60px;
                height: 42px;
                margin: 0 auto;
              }

              p {
                font-size: 14px;
                color: #666666;
                line-height: 20px;
                padding-top: 4px;
                text-align: center;
              }
            }
          }
        }
      }
    }

    .digital_bottom {
      width: 1774px;
      height: 80px;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      .digital_bottom_l {
        font-size: 14px;
        color: #333333;
        line-height: 20px;

        span {
          color: #2e4bf2;
          font-size: 24px;
        }
      }

      .digital_bottom_r {
        display: flex;
        align-items: center;

        .c3 {
          font-size: 14px;
          color: #333333;
          padding-right: 10px;
        }

        .c6 {
          font-size: 14px;
          color: #666666;
          padding-right: 14px;
          padding-left: 20px;
          display: flex;
          align-items: center;

          img {
            margin-left: 6px;
            margin-right: 10px;
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .d_f {
      display: flex;
      position: relative;

      .absolute {
        position: absolute;
        bottom: 0;
        right: -60px;
        width: 90px;
        font-size: 14px;
        color: #2e4bf2;
        line-height: 20px;
        z-index: 100;

        .empower {
          width: 90px;
          text-decoration: underline;
          cursor: pointer;
        }

        .re_upload {
          width: 90px;
          text-decoration: underline;
          display: flex;
          margin-top: 18px;

          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 4px;
          }
        }
      }
    }

    .mb30 {
      margin-bottom: 30px;
    }

    .mt20 {
      margin-top: 20px;
    }

    .mr20 {
      margin-right: 40px;
    }
  }
</style>
